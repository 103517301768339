import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import MasterLayout from '../components/MasterLayout/MasterLayout';

const IndexPage = ({ location }) => (
  <MasterLayout location={location}>
    <h1>Page error</h1>

    <p>
      Sorry, the requested page cannot be found, please visit the{' '}
      <Link to="/">homepage</Link>.
    </p>
  </MasterLayout>
);

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default IndexPage;
